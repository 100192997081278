import React, { useState } from "react";
import Select from "react-select";
import { WORKSHOP_CATEGORY_NAMES, Option } from "ui";

export type SelectOption = {
  value: string;
  label: WORKSHOP_CATEGORY_NAMES;
};

export type SelectOptions = SelectOption[];

interface ISelect {
  options: Option[];
  placeholder: string;
  isMulti: boolean;
  initialDefaultValue?: Option[];
  onSetUpdatedSelection: (selectedOptions: string[] | string) => void;
}

export const CustomSelect: React.FC<ISelect> = ({
  options,
  isMulti,
  placeholder,
  initialDefaultValue,
  onSetUpdatedSelection,
}) => {
  const [selectOptions, setSelectOptions] = useState<Option[]>(options);

  const mapSelectOptionsToOptions = (
    selectedOption: Option[] | Option | null
  ) => {
    if (Array.isArray(selectedOption)) {
      return selectedOption.map((option) => {
        return option.value;
      });
    }
    if (selectedOption === null) {
      return "";
    }

    return selectedOption.value;
  };

  const handleChange = (selectedOption: Option[] | Option | null) => {
    const mappedOptions = mapSelectOptionsToOptions(selectedOption);
    onSetUpdatedSelection(mappedOptions);
  };
  return (
    <Select
      options={selectOptions}
      placeholder={placeholder}
      isMulti={isMulti}
      isClearable
      // @ts-ignore
      onChange={(newTags) => handleChange(newTags)}
      value={initialDefaultValue}
    />
  );
};
