import React from "react";
import Slider from "react-slick";
import { LocationEnum, Workshop } from "ui";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IWorkshopWithMentorModel } from "ui";
import { headerGradient } from "../../../styles/customTheme";

const SLIDES_TO_SHOW = 3;

interface IWorkshopSlider {
  workshops: IWorkshopWithMentorModel[];
}

export const WorkshopSlider: React.FC<IWorkshopSlider> = ({ workshops }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: SLIDES_TO_SHOW,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: SLIDES_TO_SHOW,
          slidesToScroll: SLIDES_TO_SHOW,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {workshops.map((wsWithMentor, index) => (
          <div key={index}>
            <Workshop
              id={wsWithMentor.id}
              title={wsWithMentor.title}
              heroGradientStyles={headerGradient}
              mentor={wsWithMentor.mentor}
              online={LocationEnum.ONLINE in wsWithMentor.location.type}
              startDate={wsWithMentor?.dateTime?.startDate}
              endDate={wsWithMentor?.dateTime?.endDate}
              startTime={wsWithMentor?.dateTime?.startTime}
            />
          </div>
        ))}
      </Slider>
    </>
  );
};
