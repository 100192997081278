import { Box, Heading, Stack, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

export interface IUseCase {
  description: string;
  link: string;
  title: string;
}

export const UseCase: React.FC<IUseCase> = ({
  description,
  link,
  title,
  children,
}) => {
  return (
    <Box
      maxW="100%"
      minW={"280px"}
      borderRadius="lg"
      backgroundColor="blackAlpha.400"
      overflow="hidden"
      p={12}
      mb={20}
    >
      <Stack>
        <Heading as="h3" size="xl" textAlign="left" color="white">
          {title}
        </Heading>
        <Text py={8} color={"white"}>
          {description}
        </Text>
        {children}
        <Box pt={8}>
          <Link href={link} passHref>
            <Button
              color={"white"}
              variant={"outline"}
              _hover={{ backgroundColor: "whiteAlpha.100" }}
            >
              Im Angebot stöbern
            </Button>
          </Link>
        </Box>
      </Stack>
    </Box>
  );
};
