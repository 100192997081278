import { Box, Heading, Text } from "@chakra-ui/layout";
import React from "react";

interface IWorkshopFilter {
  title: string;
  subTitle?: string;
  background?: "transparent" | "white";
}

export const WorkshopFilter: React.FC<IWorkshopFilter> = ({
  title,
  subTitle,
  background = "white",
  children,
}) => {
  return (
    <Box
      p={12}
      backgroundColor={
        background === "transparent" ? "blackAlpha.400" : background
      }
      borderRadius="12px"
      mb={10}
      boxShadow="2xl"
      border={background === "transparent" ? "none" : "2px"}
      borderColor={background === "transparent" ? "none" : "gray.200"}
    >
      <Heading
        as="h4"
        size={"lg"}
        pb={4}
        color={background === "transparent" ? "white" : "black"}
      >
        {title}
      </Heading>
      {subTitle && (
        <Text
          fontSize="lg"
          mb={8}
          color={background === "transparent" ? "white" : "black"}
        >
          {subTitle}
        </Text>
      )}

      {children}
    </Box>
  );
};
