import React from "react";
import type { GetStaticProps, NextPage } from "next";
import { Box, Button, Flex } from "@chakra-ui/react";
import { HeroSection } from "../src/components/HeroSection";
import { PageContainer } from "../src/components/ui/PageContainer";
import { getWorkshopsWithMentorByCategories } from "../src/utils/firebase";

import {
  IWorkshopWithMentorModel,
  USE_CASE_CATEGORY_MAPPING,
  createCategoryUrlParams,
  DB_CATEGORIES,
  mapDBCategoriesToOptions,
} from "ui";

import { Layout } from "../src/components/Layout";
import { UseCase } from "../src/components/UseCase/UseCase";
import { WorkshopSlider } from "../src/components/WorkshopSection/WorkshopSlider";
import { CustomSelect } from "../src/components/ui/Select";
import { useRouter } from "next/router";
import { WorkshopFilter } from "../src/components/WorkshopFilter/WorkshopFilter";

interface IHome {
  futureProofOrganisationWorkshops: IWorkshopWithMentorModel[];
  productsServicesAndPositioningWorkshops: IWorkshopWithMentorModel[];
  digitalisationWorkshops: IWorkshopWithMentorModel[];
  shapeWorkCultureWorkshops: IWorkshopWithMentorModel[];
  societyAndEnvironmentWorkshops: IWorkshopWithMentorModel[];
}

const Home: NextPage<IHome> = ({
  futureProofOrganisationWorkshops,
  productsServicesAndPositioningWorkshops,
  digitalisationWorkshops,
  shapeWorkCultureWorkshops,
  societyAndEnvironmentWorkshops,
}) => {
  const router = useRouter();

  const searchCatAndRedirect = (selectedOptions: string | string[]) => {
    router.push(`/workshops?category=${selectedOptions}`);
  };

  return (
    <Layout
      pageTitle={"mentorlab | Beratung von Experten für Experten"}
      pageDescription={
        "Workshops mit erfahrenen Experten rund um die Themen der Digitalen Transformation"
      }
      og={{
        currentRelativeUrlPath: "",
        siteName: "Mentorlab | Beratung von Experten für Experten",
      }}
    >
      <Box
        style={{
          background:
            "linear-gradient(123deg, #FFFFFF 0%, #00B2FF 100%), linear-gradient(236deg, #BAFF99 0%, #005E64 100%), linear-gradient(180deg, #FFFFFF 0%, #002A5A 100%), linear-gradient(225deg, #0094FF 20%, #BFF4ED 45%, #280F34 45%, #280F34 70%, #FF004E 70%, #E41655 85%, #B30753 85%, #B30753 100%), linear-gradient(135deg, #0E0220 15%, #0E0220 35%, #E40475 35%, #E40475 60%, #48E0E4 60%, #48E0E4 68%, #D7FBF6 68%, #D7FBF6 100%)",
          backgroundBlendMode: "overlay, overlay, overlay, darken, normal",
        }}
      >
        <PageContainer>
          <HeroSection />
          <Box pt={10}>
            <WorkshopFilter
              title={"Finde hier das passende Angebot"}
              subTitle="Filtere nach einer Kategorie oder stöbere nach ausgewählten Transformationszielen"
              background="transparent"
            >
              <CustomSelect
                options={mapDBCategoriesToOptions(DB_CATEGORIES)}
                placeholder={"z.B. Leadership, Strategie, Diversität, ..."}
                isMulti={false}
                onSetUpdatedSelection={searchCatAndRedirect}
              />
            </WorkshopFilter>
          </Box>

          <Box pt={10} id="first-use-case">
            {futureProofOrganisationWorkshops && (
              <UseCase
                description="Unternehmer, Führungskräfte und Gestalter in Organisationen müssen künftig in hochkomplexen und dynamischen Umfeldern Entscheidungen treffen, um die Zukunftsfähigkeit des Unternehmens zu sichern. Bei mentorlab findest du Inspiration und Anleitungen rund um die Themen Strategie, Leadership, Resilienz und Finanzierung"
                link={`/workshops?${createCategoryUrlParams(
                  USE_CASE_CATEGORY_MAPPING.futureProofOrganisation
                )}`}
                title={"Das Unternehmen erfolgreich in die Zukunft führen"}
              >
                <WorkshopSlider workshops={futureProofOrganisationWorkshops} />
              </UseCase>
            )}

            {digitalisationWorkshops && (
              <UseCase
                description="Technologie und Daten sind beides: Treiber der Transformation, sowie wichtige Gestaltungsräume, um Prozesse effizienter zu gestalten und die Wertschöpfung neu auszurichten. In den Kategorien Digitalisierung und Technologie findest du Anregungen und Knowhow zum Thema"
                link={`/workshops?${createCategoryUrlParams(
                  USE_CASE_CATEGORY_MAPPING.digitalisation
                )}`}
                title={
                  "Durch Digitalisierung die Organisation und das Geschäft voranbringen"
                }
              >
                <WorkshopSlider workshops={digitalisationWorkshops} />
              </UseCase>
            )}
            {productsServicesAndPositioningWorkshops && (
              <UseCase
                description="Innovationsfähigkeit als wesentlicher Erfolgsfaktor für die neue Wirtschaft: Innovation muss in der Organisation entstehen und am Markt zur Wirkung gelangen. Wir haben wichtige Impulse rund um die Themen Innovation, Kreativität und Marketing für euch zusammen gefasst"
                link={`/workshops?${createCategoryUrlParams(
                  USE_CASE_CATEGORY_MAPPING.productsServicesAndPositioning
                )}`}
                title={
                  "Neue Produkte und Services entwickeln und positionieren"
                }
              >
                <WorkshopSlider
                  workshops={productsServicesAndPositioningWorkshops}
                />
              </UseCase>
            )}

            {shapeWorkCultureWorkshops && (
              <UseCase
                description="Richte Strukturen und Abläufe in der Organisation konsequent auf die Wertschöpfung für deine Kunden aus und lerne neue, flexible Formen der Zusammenarbeit kennen. Mehr zu den Themen Organisation, Prozesse und New Work findest du hier"
                link={`/workshops?${createCategoryUrlParams(
                  USE_CASE_CATEGORY_MAPPING.shapeWorkCulture
                )}`}
                title={"Formen der Zusammenarbeit neu entdecken und gestalten"}
              >
                <WorkshopSlider workshops={shapeWorkCultureWorkshops} />
              </UseCase>
            )}

            {societyAndEnvironmentWorkshops && (
              <UseCase
                description="Zukunftsfähige Unternehmen übernehmen Verantwortung über die Wirtschaftlichkeit hinaus: Hier kannst du dir Orientierung zu den Themen Diversität, Nachhaltigkeit und Wellbeing holen und von Good Practices aus anderen Organisationen lernen"
                link={`/workshops?${createCategoryUrlParams(
                  USE_CASE_CATEGORY_MAPPING.societyAndEnvironment
                )}`}
                title={
                  "Als Organisation einen Beitrag zu Gesellschaft und Umwelt leisten"
                }
              >
                <WorkshopSlider workshops={societyAndEnvironmentWorkshops} />
              </UseCase>
            )}
          </Box>
          <Flex mt={10} justifyContent={"center"} alignItems={"center"}>
            <Button
              variant={"outline"}
              onClick={() => router.push("/offers")}
              color={"white"}
              _hover={{ background: "whiteAlpha.100" }}
            >
              Gesamtes Angebot ansehen
            </Button>
          </Flex>
        </PageContainer>
      </Box>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const count = 6;

  let futureProofOrganisationWorkshops =
    await getWorkshopsWithMentorByCategories(
      USE_CASE_CATEGORY_MAPPING.futureProofOrganisation,
      count,
      { onlyActive: true }
    );
  if (!futureProofOrganisationWorkshops) {
    futureProofOrganisationWorkshops = [];
  }

  let productsServicesAndPositioningWorkshops =
    await getWorkshopsWithMentorByCategories(
      USE_CASE_CATEGORY_MAPPING.productsServicesAndPositioning,
      count,
      {
        onlyActive: true,
      }
    );
  if (!productsServicesAndPositioningWorkshops) {
    productsServicesAndPositioningWorkshops = [];
  }

  let digitalisationWorkshops = await getWorkshopsWithMentorByCategories(
    USE_CASE_CATEGORY_MAPPING.digitalisation,
    count,
    { onlyActive: true }
  );
  if (!digitalisationWorkshops) {
    digitalisationWorkshops = [];
  }

  let shapeWorkCultureWorkshops = await getWorkshopsWithMentorByCategories(
    USE_CASE_CATEGORY_MAPPING.shapeWorkCulture,
    count,
    { onlyActive: true }
  );
  if (!shapeWorkCultureWorkshops) {
    shapeWorkCultureWorkshops = [];
  }

  let societyAndEnvironmentWorkshops = await getWorkshopsWithMentorByCategories(
    USE_CASE_CATEGORY_MAPPING.societyAndEnvironment,
    count,
    { onlyActive: true }
  );
  if (!societyAndEnvironmentWorkshops) {
    societyAndEnvironmentWorkshops = [];
  }

  return {
    props: {
      futureProofOrganisationWorkshops,
      productsServicesAndPositioningWorkshops,
      digitalisationWorkshops,
      shapeWorkCultureWorkshops,
      societyAndEnvironmentWorkshops,
    },
    revalidate: 10,
  };
};

export default Home;
