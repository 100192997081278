import Head from "next/head";
import Script from "next/script";
import React from "react";
import { CookieNotification } from "ui";

const MENTORLAB_BASE_URL = "https://mentorlab.de";

interface ILayout {
  pageTitle: string;
  pageDescription: string;
  og: {
    currentRelativeUrlPath: string;
    siteName: string;
  };
}

export const Layout: React.FC<ILayout> = ({
  children,
  pageTitle,
  pageDescription,
  og,
}) => {
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} key="ogtitle" />
        <meta
          property="og:description"
          content={pageDescription}
          key="ogdesc"
        />
        <meta
          property="og:url"
          content={`${MENTORLAB_BASE_URL}${og.currentRelativeUrlPath}`}
          key="ogurl"
        />

        <meta
          property="og:image"
          content={`${MENTORLAB_BASE_URL}/logo/rgb/mentorlab_logo_meta_seo.jpg`}
          key="ogimage"
        />

        <meta property="og:site_name" content={og.siteName} key="ogsitename" />
      </Head>
      {children}
      <CookieNotification />
      <Script
        id="visitor_analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          <!-- VISA Tracking Code for https://mentorlab.de -->
          (function(v,i,s,a,t){v[t]=v[t]||function(){(v[t].v=v[t].v||[]).push(arguments)};if(!v._visaSettings){v._visaSettings={}}v._visaSettings[a]={v:'1.0',s:a,a:'1',t:t};var b=i.getElementsByTagName('body')[0];var p=i.createElement('script');p.defer=1;p.async=1;p.src=s+'?s='+a;b.appendChild(p)})(window,document,'//app-worker.visitor-analytics.io/main.js','fb829f86-008d-11ed-b589-901b0edac50a','va')
          <!-- VISA Tracking Code for https://mentorlab.de -->
          `,
        }}
      />
    </>
  );
};
